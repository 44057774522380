<template>
    <div></div>
</template>
<script>
import { getOpenId, getAppUserId, getAppLoginToken } from '../../utils/store';
export default ({
    name: 'index',
    data() {
        return {
            groupPurchaseId: this.$route.query.id,
        }
    },
    mounted() {
        let user_id = getAppUserId()
        let open_id = getOpenId()
        let login_token = getAppLoginToken()
        let login = false
        if (user_id && user_id != '' && open_id && open_id != '' && login_token && login_token != '') {
            login = true
        }
        if (!login) {
            const appId = 'wxda915e8dce4fe609'
            const redirectUrl = 'https://login.kenengxueyuan.com/app-groupPurchase2?id=' + this.groupPurchaseId
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1'
        } else {
            this.$router.replace({
                path: '/app-groupPurchase2',
                query: {
                    id: this.groupPurchaseId
                }
            })
        }
    }
})
</script>
